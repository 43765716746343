import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import { Link } from "react-scroll"

import "../styles/component/faq.scss"
import "../styles/component/pricing-faq.scss"
import "../styles/component/sidebar-small.scss"
import "../styles/pages/seo-post.scss"

import DropdownIcon from "../assets/images/dropdown-icon.svg"
import EsArrowDown from "../assets/images/resources/es-arrow-down.png"
import ImageCarousal from "../components/common/image-caraousal"
import FAQ from "../components/seo/faq"
// import { OverlayScrollbarsComponent } from "overlayscrollbars-react"

function SeoPostContent(props) {
  const { seo } = props
  let [showSubtitle, setShowSubtitle] = useState()

  let contentArray = parse(seo.content.html)

  let wrapCount = []
  let wrapContent = []

  let headingCount = -1
  let subheadingCount = 0

  contentArray.map((ht, i) => {
    if (i === 0 && ht.type !== "h3" && ht.type !== "h2") {
      wrapCount.push({
        index: i,
        type: "text",
        id: `introtext`,
      })
    }
    if (ht.type === "h2") {
      headingCount++
      wrapCount.push({
        index: i,
        type: "heading",
        id: `heading${headingCount}`,
      })
    }
    if (ht.type === "h3") {
      wrapCount.push({
        index: i,
        type: "subheading",
        id: `subheading${subheadingCount}`,
        headingId: `heading${headingCount}`,
      })
      subheadingCount++
    }
  })

  for (var i = 0; i < wrapCount.length; i++) {
    if (i === wrapCount.length - 1) {
      wrapContent.push({
        content: contentArray.slice(wrapCount[i].index),
        type: wrapCount[i].type,
      })
    } else {
      wrapContent.push({
        content: contentArray.slice(wrapCount[i].index, wrapCount[i + 1].index),
        type: wrapCount[i].type,
      })
    }
  }

  const addClasses = buttons => {
    buttons.forEach(btn => {
      btn.classList.add("btn", "btn-primary", "white", "arrow")
    })
  }

  useEffect(() => {
    let allH2 = document.querySelectorAll("#seoContent > .main")
    let allH3 = document.querySelectorAll("#seoContent > .sub")

    let ctaButtons = document.querySelectorAll(".rich-cta td:last-child p a")
    let ebookButtons = document.querySelectorAll(".ebook-cta td:last-child p a")

    addClasses(ctaButtons)
    addClasses(ebookButtons)

    allH2.forEach((tag, index) => {
      tag.id = "heading" + index
    })
    allH3.forEach((tag, index) => {
      tag.id = "subheading" + index
    })
  }, [])

  const [currentNavValue, setCurrentNavValue] = useState("")

  useEffect(() => {
    // setCurrentNavValue(contentArray[0].props.children)
    setCurrentNavValue(
      contentArray[wrapCount.find(wrap => wrap.type === "heading").index].props
        .children
    )
  }, [])

  function openDropdown(Id) {
    setShowSubtitle(Id)
  }

  function toggleDropDown(Id) {
    if (showSubtitle === Id) {
      setShowSubtitle("")
    } else {
      setShowSubtitle(Id)
    }
  }

  return (
    <div className={`${props.className}`}>
      <div className="seo-content">
        <center>
          <Container className="seo-container">
            <section
              className="es-nav-wrap fe-gl-nav-mob d-mob"
              id="navContent"
            >
              <Container>
                <center>
                  <div className="outer">
                    <div className="select">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="drp-btn">
                          {currentNavValue}
                          <img
                            src={EsArrowDown}
                            height="1"
                            width="1"
                            alt="img"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          renderOnMount={true}
                          rootCloseEvent={"click"}
                          id="drp-menu"
                        >
                          {wrapCount.map((t, i) => {
                            return (
                              t.type !== "text" && (
                                <Dropdown.Item>
                                  <p
                                    style={{
                                      paddingLeft:
                                        t.type === "subheading" ? "16px" : "0",
                                      height:
                                        t.type === "subheading"
                                          ? "24px"
                                          : "32px",
                                      fontWeight:
                                        t.type === "subheading" && "400",
                                      color: t.type === "heading" && "#170426",
                                      overflow: "hidden",
                                      lineHeight:
                                        t.type === "subheading" && "24px",
                                    }}
                                  >
                                    <Link
                                      to={t.id}
                                      spy={true}
                                      offset={-250}
                                      smooth={true}
                                      duration={150}
                                      activeClass="active"
                                      onSetActive={() => {
                                        setCurrentNavValue(
                                          contentArray[t.index].props.children
                                        )
                                      }}
                                    >
                                      {contentArray[t.index].props.children}
                                    </Link>
                                  </p>
                                </Dropdown.Item>
                              )
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </center>
              </Container>
            </section>
            <Row>
              <Col lg={4}>
                <div className="d-dsk ">
                  {" "}
                  <div className="sticky-top seo-opt">
                    <div className="seo-options-block">
                      <div className="title font-roboto">QUICK ACCESS </div>
                      {/* <OverlayScrollbarsComponent
                                      style={{ maxHeight: "350px", color: "#dddddd" }}
                                      options={{ resize: ["v"], scrollbars: {autoHide: 'leave'} }}
                                    > */}
                      {wrapCount.map((t, i) => {
                        if (t.type === "heading") {
                          return (
                            <div className="options main">
                              <div className="position-relative value p14">
                                <Link
                                  to={t.id}
                                  spy={true}
                                  offset={-400}
                                  smooth={true}
                                  duration={150}
                                  activeClass="active"
                                  onSetActive={function() {
                                    openDropdown(t.id)
                                  }}
                                >
                                  <p
                                    onClick={e => {
                                      toggleDropDown(t.id)
                                    }}
                                  >
                                    {contentArray[t.index].props.children}{" "}
                                  </p>
                                </Link>
                                {wrapCount[i + 1] &&
                                  wrapCount[i + 1].type === "subheading" && (
                                    <img
                                      id={headingCount}
                                      onClick={e => {
                                        toggleDropDown(t.id)
                                      }}
                                      style={{
                                        transform:
                                          showSubtitle === t.id &&
                                          "rotate(180deg)",
                                      }}
                                      className={`position-absolute dropdown-icon`}
                                      src={DropdownIcon}
                                      alt="dropdown"
                                    />
                                  )}
                              </div>
                            </div>
                          )
                        }
                        if (t.type === "subheading") {
                          return (
                            <div
                              style={{
                                display:
                                  showSubtitle === t.headingId
                                    ? "block"
                                    : "none",
                              }}
                              className="options sub"
                            >
                              <div className="value p14">
                                <Link
                                  to={t.id}
                                  spy={true}
                                  offset={-400}
                                  smooth={true}
                                  duration={150}
                                  activeClass="active"
                                  onSetActive={function() {
                                    openDropdown(t.headingId)
                                  }}
                                >
                                  <p>{contentArray[t.index].props.children} </p>
                                </Link>
                              </div>
                            </div>
                          )
                        }
                      })}
                      {/* </OverlayScrollbarsComponent> */}
                    </div>
                    {seo.isCarouselAvailable === "Yes" && (
                      <div className="caraousel-widget">
                        <ImageCarousal
                          images={seo.carsouelImages}
                          links={seo.carouselLinks}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="rich-text secondary-font" id="seoContent">
                  {wrapContent.map(wrap => {
                    return (
                      <div
                        className={`wrap ${
                          wrap.type === "heading"
                            ? "main"
                            : wrap.type === "subheading" && "sub"
                        }`}
                      >
                        {wrap.content}
                      </div>
                    )
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </center>
      </div>

      {seo.faqQuestions.length ? (
        <div className="faq">
          <Container>
            <Row>
              <Col lg={3}>
                <h2>Frequently asked questions</h2>
                <span>
                  <p>We have them answered.</p>
                </span>
              </Col>
              <Col>
                <div className="section">
                  <FAQ question={seo.faqQuestions} answer={seo.faqAnswers} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : null}
    </div>
  )
}

export default SeoPostContent
