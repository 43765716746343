import { StaticQuery, graphql } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import "../styles/component/faq.scss"
import "../styles/component/pricing-faq.scss"
import "../styles/component/sidebar-small.scss"
import "../styles/pages/seo-post.scss"

import CTA from "../components/common/CTA"
import AnythingCards from "../components/common/anything-can-handle-cards"
import Frame from "../components/common/frame"
import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
// import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import Buttons from "../components/common/button"
import SeoPostContent from "./seo-post-content"

function SeoPost({ pageContext }) {
  const { seo } = pageContext

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentSEOPostNew {
            SuperOps {
              pages(where: { title: "SEO Post" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <div className="blg">
            {data.SuperOps.pages.map(page => {
              const { title, navigationBlock } = page

              return (
                <>
                  {seo.scriptJson.length > 0 && (
                    <script type="application/ld+json">
                      {JSON.stringify(seo.scriptJson)}
                    </script>
                  )}
                  <Frame seo={seo.seo}>
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page={title}
                        color={seo.heroBgColor}
                      />
                    </header>

                    <section className="seo-main">
                      <div
                        className="seo-hero"
                        style={{ background: seo.heroBgColor }}
                      >
                        {/* Conditional rendering for subtitle exist */}
                        {seo.subTitle ? (
                          <Container>
                            {seo.tag && (
                              <div className="seo-tag">{seo.tag}</div>
                            )}
                            <Row>
                              <Col lg={6}>
                                <Slide delay="200">
                                  <div className="seo-hero-heading">
                                    <h1> {seo.title} </h1>
                                  </div>
                                </Slide>
                              </Col>

                              <Col lg={5}>
                                <Slide delay="200">
                                  {/* <div className="seo-tag">{seo.tag}</div> */}
                                  <div className="seo-hero-subtitle p16">
                                    <p className="subtext">{seo.subTitle} </p>
                                    {seo.heroButtonText && (
                                      <Buttons
                                        theme="primary sm"
                                        arrow
                                        text={seo.heroButtonText}
                                        link={seo.heroButtonLink}
                                      />
                                    )}
                                  </div>
                                </Slide>
                              </Col>
                            </Row>
                          </Container>
                        ) : (
                          <Container>
                            <Row>
                              <Col lg={4}>
                                <Slide delay="200">
                                  <div className="seo-tag">{seo.tag}</div>
                                </Slide>
                              </Col>
                              <Col lg={8}>
                                <Slide delay="200">
                                  <div className="seo-hero-heading">
                                    <h1> {seo.title} </h1>
                                  </div>
                                </Slide>
                              </Col>
                            </Row>
                          </Container>
                        )}
                      </div>

                      <SeoPostContent seo={seo} />
                      
                      <div className="">
                        <Container>
                          {seo.ctaAvailable === "Yes" && (
                            <CTA data={[seo.ctaBox]} />
                          )}
                        </Container>
                      </div>
                      {seo.stayInTheKnowContent.length && (
                        <div className="stay-in-the-know">
                          <Container>
                            <AnythingCards
                              heading="Stay in the know!"
                              ctaText="explore library"
                              ctaLink="blog"
                              content={seo.stayInTheKnowContent}
                            />
                          </Container>
                        </div>
                      )}
                    </section>
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default SeoPost
