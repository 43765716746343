import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import "../../styles/component/latest-blog-cards.scss"

import BlogCards from "../seo/seo-post-blog-cards"
import SVGIcon from "./SVGIcon"

function AnythingCards(props) {
  return (
    <div className="latest-blog-cards">
      <Container>
        <Row>
          <Col lg={3}>
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-easing="ease-out-bounce"
              data-sal-duration="700"
            >
              <h2> {props.heading} </h2>
            </div>

            <span className="a14">
              <div
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease-out-bounce"
                data-sal-duration="700"
              >
                <Link
                  to={`/${props.ctaLink}`}
                  className="position-relative link"
                >
                  {props.ctaText}
                  <SVGIcon
                    name="hypertext-arrow-right"
                    className="arrow-right"
                  />
                </Link>
              </div>
            </span>
          </Col>
          <Col lg={9}>
            <BlogCards posts={props.content} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AnythingCards
