import { Link } from "gatsby"
import React from "react"
import SVGIcon from "../common/SVGIcon"
import CarouselComponent from "../common/carousel-component"

function BlogCards(props) {
  const ContainerCard = props => {
    const {
      seoTitle,
      seoSlug,
      epiSlug,
      superpodPage,
      title,
      coverImage,
      image,
      tags,
      slug,
      seo,
    } = props.post

    const episodeSlug =
      superpodPage === "podcast"
        ? `superpod/season1/${epiSlug}`
        : superpodPage === "bytes"
        ? `superpod-bytes/${epiSlug}`
        : `superpod/season2/${epiSlug}`

    const epiHomeSlug =
      superpodPage === "podcast"
        ? `superpod/season1`
        : superpodPage === "bytes"
        ? `superpod-bytes`
        : `superpod/season2`

    return (
      <a href={`${slug ? "/blog" : ""}/${slug || seoSlug || episodeSlug}`}>
        <div className="wrap">
          <div className="cover">
            <img
              src={seo?.image?.url || coverImage?.url || image?.url}
              alt={title}
              height="100"
              width="100"
            />
          </div>
          <div className="content position-relative">
            <div>
              {tags ? (
                tags.map((tag, ti) => {
                  return (
                    <div className="tag p14" key={ti}>
                      <a href={`/blog/categories/${tag.toLowerCase()}`}>
                        <p>{tag}</p>
                      </a>
                      <p
                        className="line"
                        style={{
                          display: ti === tags.length - 1 && "none",
                        }}
                      >
                        |
                      </p>
                    </div>
                  )
                })
              ) : seoTitle ? (
                <></>
              ) : (
                <div className="tag p14">
                  <a href={epiHomeSlug}>
                    <p>{"EPISODE"}</p>
                  </a>
                </div>
              )}
            </div>
            <span className="p16">
              <p className={`fw-bold heading ${seoTitle ? "mt-0" : ""}`} title={title}>
                {title || seoTitle}
              </p>
            </span>

            <span className="a14">
              <Link
                to={`/${slug || seoSlug || episodeSlug}`}
                className="position-absolute link d-flex align-items-center"
              >
                read more
                <SVGIcon name="arrowright" className="arrow-right" />
              </Link>
            </span>
          </div>
        </div>
      </a>
    )
  }

  return (
    <>
      <div className="grid-container no-caros">
        {props.posts.map((post, i) => {
          const { image, coverImage, seo } = post
          return (
            (image || coverImage || seo?.image) && (
              <div className="grid-lg-3">
                <div
                  data-sal="slide-up"
                  data-sal-delay={(i + 1) * 200}
                  data-sal-easing="ease-out-bounce"
                  data-sal-duration="700"
                >
                  <ContainerCard post={post} />
                </div>
              </div>
            )
          )
        })}
      </div>

      <div className="grid-container caros">
        <CarouselComponent
          swipeable
          items={1}
          displayArrow={props.posts.length > 1 ? true : false}
        >
          {props.posts.map((post, i) => {
            const { image, coverImage, seo } = post
            return (
              (image || coverImage || seo?.image) && (
                <div className="caros-item">
                  <ContainerCard post={post} />
                </div>
              )
            )
          })}
        </CarouselComponent>
      </div>
    </>
  )
}

export default BlogCards
